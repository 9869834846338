<template>
  <div :class="propsClassName">
    <div class="heading_droupdown d-flex align-items-center" v-if="device==='mobile'">
      <h4><span><i class="fa-solid fa-location-dot"></i></span>{{ $t("sabre.search-panel.from-where")}}</h4>
    </div>
    <div class="input-group">
      <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-magnifying-glass"></i></span>
      <input type="text" class="form-control" v-model="filterDest"
      :placeholder="$t('sabre.search-panel.where-flying-from')">
    </div>
    <div class="fromwhered_chackbox" v-if="hotOptions && hotOptions.length">
      <h4> {{$t("sabre.search-panel.last-searches")}}</h4>
      <div class="row g-3 row-cols-3">
        <div class="form-check col" v-for="(dest, inx) in hotOptions" :key="inx" @click="() => selectDest(dest.code)">
          <input class="form-check-input" type="checkbox"
           :checked="chosenDestinationCodes.includes(dest.code)">
          <label class="form-check-label">{{dest.label}}</label>
        </div>
      </div>
    </div>
    <div class="fromwhered_chackbox" v-if="destinations && destinations.length">
      <h4>{{$t("sabre.search-panel.top-destinations")}} </h4>
      <div class="row g-3 row-cols-3">
        <div class="form-check col" v-for="(dest, inx) in destinations" :key="hotOptions.length+inx" @click="() => selectDest(dest.code)">
          <input class="form-check-input" type="checkbox"
          :checked="chosenDestinationCodes.includes(dest.code)">
          <label class="form-check-label" >{{dest.label}}</label>
        </div>
      </div>
    </div>

    <button class="cleanup_close" v-if="device==='mobile'" @click="() => save()"><i class="fas fa-times"></i></button>

    <div class="btn-group-area d-flex justify-content-end">
      <button class="cleanup_btn" @click="() => selectDest()">{{$t("sabre.buttons.clean")}}</button>
      <button class="save_btn" @click="() => save()">{{$t("sabre.buttons.save")}}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'select-destination',
  props: {
    propsClassName: {
      type: String,
      default: '',
    },
    category: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    hotOptions: {
      type: Array,
      default: () => [],
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      chosenDestinationCodes: 'GET_FH_CHOSEN_DESTINATIONS',
    }),
    destinations() {
      const { options, filterDest } = this;
      const lowerLetter = filterDest.toLowerCase();
      return options?.filter((dest) => (dest.code.toLowerCase().includes(lowerLetter) || dest.label?.toLowerCase().includes(lowerLetter))).filter((dest) => dest.label) || [];
    },
  },
  data() {
    return {
      filterDest: '',
    };
  },
  methods: {
    selectDest(code) {
      const { isMultiple, chosenDestinationCodes, category } = this;
      const inx = chosenDestinationCodes.findIndex((c) => c === code);

      let storeCodes = [...chosenDestinationCodes];
      if (!code) {
        storeCodes = [];
      } else if (inx > -1) {
        storeCodes.splice(inx, 1);
      } else if (isMultiple) {
        storeCodes.push(code);
      } else {
        storeCodes = [code];
      }
      this.$store.dispatch('FETCH_CALENDAR_DATA_FROM_DEST', { dest: storeCodes, category });
    },
    save() {
      this.$emit('finished');
    },
  },
};
</script>
